import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'Layout',
    redirect: '/home',
    component: () => import('../views/Layout.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        meta: {
          title: '首页'
        },
        component: () => import('../views/Home.vue')
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../views/Empty.vue'),
        meta: {
          title: '新闻中心'
        },
        children: [
          {
            path: '/newsList',
            name: 'NewsList',
            component: () => import(/* webpackChunkName: "about" */ '../views/NewsList.vue'),
            meta: {
              title: '新闻列表'
            }
          },
          {
            path: '/addnews',
            name: 'AddNews',
            meta: {
              title: '编辑新闻',
              keepAlive: true
            },
            component: () => import(/* webpackChunkName: "about" */ '../views/AddNews.vue')
          },
          {
            path: '/previewnews',
            name: 'PreviewNews',
            meta: {
              title: '预览新闻'
            },
            component: () => import(/* webpackChunkName: "about" */ '../views/PreviewNews.vue')
          }
        ]
      },
      {
        path: '/message',
        name: 'Message',
        component: () => import('../views/Empty.vue'),
        meta: {
          title: '留言管理'
        },
        children: [
          {
            path: '/messageList',
            name: 'MessageList',
            component: () => import(/* webpackChunkName: "about" */ '../views/Message.vue'),
            meta: {
              title: '留言列表'
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
