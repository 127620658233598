<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
//import Layout from './views/Layout.vue'

export default {
  name: 'app',
  // components: {
  //   Layout
  // }
}
</script>

<style>
@import 'assets/font.css';

* {
  margin: 0;
  padding: 0;
}

.padd {
  background: #fff;
  padding: 1%;
}
</style>
